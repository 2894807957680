<template>
  <div class="pass_change">
    <form action="" @submit.prevent="change_pass">
      <div class="title">
        <h2>{{ $t("reset_pass.change_password") }}</h2>
      </div>
      <div class="input">
        <input
          :type="passtype"
          :placeholder="$t('admin.password')"
          v-model="form.password"
        />
        <i :class="show_pass" @click="showPassword"></i>
      </div>
      <div class="input">
        <input
          :type="passtype2"
          :placeholder="$t('reset_pass.repear_password')"
          v-model="repeat_password"
        />
        <i :class="show_pass2" @click="showPassword2"></i>
      </div>
      <div class="button">
        <button type="submit">
          {{ $t("admin.edit") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import env from "../../../env.json";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "passchange",
  data() {
    return {
      form: {
        password: "",
      },
      repeat_password: "",
      token: "",
      isLoading: true,
      passtype: "password",
      passtype2: "password",
      show_pass: "far fa-eye-slash",
      show_pass2: "far fa-eye-slash",
    };
  },
  created() {
    this.token = this.$route.params.id;
    axios
      .post(
        `${env.host}/forgot-password/check`,
        {},
        {
          headers: { Authorization: `Bearer ${this.token}` },
        }
      )
      .then((res) => {
        this.isLoading = false;
        console.log(res);
      });
  },
  methods: {
    change_pass() {
      if (this.form.password === this.repeat_password) {
        axios
          .post(`${env.host}/forgot-password/change`, this.form, {
            headers: { Authorization: `Bearer ${this.token}` },
          })
          .then((res) => {
            Swal.fire({
              text: this.$t("reset_pass.success"),
            });
            this.$router.push("/admin");
          });
      } else {
        Swal.fire({
          icon: "error",
          text: this.$t("reset_pass.passwords_do_not_match"),
        });
      }
    },
    showPassword() {
      if (this.passtype === "password") {
        this.passtype = "text";
        this.show_pass = "far fa-eye";
      } else {
        this.passtype = "password";
        this.show_pass = "far fa-eye-slash";
      }
    },
    showPassword2() {
      if (this.passtype2 === "password") {
        this.passtype2 = "text";
        this.show_pass2 = "far fa-eye";
      } else {
        this.passtype2 = "password";
        this.show_pass2 = "far fa-eye-slash";
      }
    },
  },
};
</script>

<style scoped>
.pass_change {
  margin-top: 0 !important;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
}
.pass_change form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.pass_change .input {
  position: relative;
}
.pass_change input {
  width: 100%;
  height: 50px;
  border-radius: 0px;
  border: 1px solid #dadada;
  outline: none;
  padding-left: 20px;
}
.pass_change input:focus {
  border: 1px solid royalblue;
  transition: 0.3s;
}
.pass_change .input i {
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
}
.pass_change button {
  width: 100%;
  height: 45px;
  border: none;
  background: royalblue;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>